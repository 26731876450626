<template>
  <div class="trading-panel">
    <div class="box pb-5">
      <div class="trading__titles row">
        <div class="text col-9">
          <span>Additional Balance</span>
        </div>
      </div>
      <div class="row mt-4 title-table ">
        <div class="col-6 text-center">
          <h5 class="text-white">Balance</h5>
        </div>
        <div class="col-6 text-center">
          <h5 class="text-white">Description</h5>
        </div>
      </div>
      <div
        class="row table-row mt-3"
        v-for="balance in additionalBalance"
        :key="balance.id"
      >
        <template v-if="additionalBalance.length">
          <div class="col-6">
            <p class="text-white text-center">{{ balance.balance | intl }}</p>
          </div>
          <div class="col-6">
            <p class="text-white text-center">{{ balance.description }}</p>
          </div>
        </template>
      </div>
    </div>
    <!-- <template v-else>
      <div class="box">
        <UiPreloader />
      </div>
      <div class="ugrade"></div>
    </template> -->
  </div>
</template>

<script>
import { mapState } from 'vuex'
export default {
  computed: {
    ...mapState('User', ['additionalBalance']),
  },
}
</script>

<style lang="scss" scoped>
.title-table {
  border-bottom: 2px solid #19385a;
}
.box {
  border-radius: 20px !important;
}
.button {
  position: relative;
  button {
    width: max-content;
    position: absolute;
    padding: 5px 15px;
    top: 50%;
    transform: translateY(-50%);
    right: 0;
    background-color: transparent;
    color: #fff;
    border: 1px solid #f1f601;
    transition: 0.3s ease-out;
    &:hover {
      background: #f1f601;
      color: black;
    }
  }
}
</style>
