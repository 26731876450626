<template>
  <div class="activity-panel">
    <div class="activity__titles">
      <div class="text">
        <span>{{ $t('account_page.activity.title') }}</span>
      </div>
      <div class="activity__watch-more">
        <span>{{ $t('account_page.activity.watch') }}</span>
      </div>
    </div>
    <div class="activity__custom-tabs">
      <b-tabs content-class="mt-3">
        <b-tab title="Activity" active>
          <div class="android" v-for="item in activityList" :key="item._id">
            <div class="android__location">
              <div class="title">
                <span
                  >{{ item.device.device.device.brand }}
                  {{ item.device.device.device.type }}</span
                >
              </div>
              <div class="location">
                <span
                  >{{ item.device.ipData.cityName }}
                  {{ item.device.ipData.countryCode }}</span
                >
              </div>
            </div>
            <div class="android__ipAdress">
              <div class="ip">
                <span>{{ item.device.ipData.ip }}</span>
              </div>
              <div class="date">
                <span>{{ item.createdAt | luxon('yyyy-MM-dd HH:mm:ss') }}</span>
              </div>
            </div>
          </div>
        </b-tab>
        <b-tab title="Devices">
          <div class="android" v-for="item in deviceList" :key="item._id">
            <div class="android__location">
              <div class="title">
                <span v-if="item.device.client && item.device"
                  >{{ item.device.client.name }}
                  {{ item.device.client.version }} ({{
                    item.device.device.brand
                  }}
                  {{ item.device.device.type }})</span
                >
              </div>
              <div class="location">
                <span
                  >{{ item.ipData.cityName }}
                  {{ item.ipData.countryCode }}</span
                >
              </div>
            </div>
            <div class="android__ipAdress">
              <div class="ip">
                <span>{{ item.ipData.ip }}</span>
              </div>
              <div class="date">
                <span>{{ item.createdAt | luxon('yyyy-MM-dd HH:mm:ss') }}</span>
              </div>
            </div>
          </div>
        </b-tab>
      </b-tabs>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
export default {
  computed: {
    activityList() {
      return this.getterMeActivities.slice(0, 5)
    },

    deviceList() {
      return this.getterMeDevices.slice(0, 5)
    },

    ...mapGetters('Security', ['getterMeActivities', 'getterMeDevices']),
  },

  mounted() {
    this.getMeActivity()
  },

  methods: {
    ...mapActions('Security', ['getMeActivity']),
  },
}
</script>

<style></style>
