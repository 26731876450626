<template>
  <div class="announcements-panel">
    <div class="announcements__titles">
      <div class="announcements__text">
        <span>{{ $t('account_page.announcements.title') }}</span>
      </div>
      <div class="announcements__watch-more">
        <router-link :to="{ name: 'news' }">
          <span>{{ $t('account_page.announcements.watch') }}</span>
        </router-link>
      </div>
    </div>
    <div class="announcements__info">
      <div class="announcements__box" v-for="item in newsList" :key="item._id">
        <div class="image-news">
          <img :src="item.imgUrl" :alt="item.title" />
        </div>
        <div class="announcements__description">
          <span>{{ item.preview | intl }}</span>
        </div>
        <div class="announcements__date">
          <span>{{ item.createdAt | luxon('yyyy-MM-dd HH:mm:ss') }}</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex'
export default {
  data() {
    return {
      newsList: [],
    }
  },

  mounted() {
    this.getNews({ limit: 3, sort: { createdAt: -1 } }).then(data => {
      this.newsList = data.docs
    })
  },

  methods: {
    ...mapActions('News', ['getNews']),
  },
}
</script>

<style></style>
