<template>
  <div class="trading-panel">
    <div class="box pb-5">
      <div class="trading__titles row">
        <div class="text col-9">
          <span>Active Package</span>
        </div>
        <div class="button col-3">
          <button @click="$emit('openFeedOff')">
            Buy more
          </button>
        </div>
      </div>
      <div class="row mt-4 title-table ">
        <div class="col-3">
          <h5 class="text-white">Package</h5>
        </div>
        <div class="col-3 ">
          <h5 class="text-white">Investment</h5>
        </div>
        <div class="col-3">
          <h5 class="text-white">Expiration date</h5>
        </div>
        <div class="col-3 ">
          <h5 class="text-white">Profit</h5>
        </div>
      </div>
      <div class="row table-row mt-3" v-for="price in pricing" :key="price.id">
        <template v-if="price.pricePackage">
          <div class="col-3">
            <p class="text-white">{{ price.pricePackage.title | intl }}</p>
          </div>
          <div class="col-3 ">
            <p class="text-white">{{ price.invest | intl }}</p>
          </div>
          <div class="col-3 ">
            <p class="text-white">{{ price.pricePackage.term | intl }}</p>
          </div>
          <div class="col-3 ">
            <p class="text-white">60 USD</p>
          </div>
        </template>
      </div>
    </div>
    <!-- <template v-else>
      <div class="box">
        <UiPreloader />
      </div>
      <div class="ugrade"></div>
    </template> -->
  </div>
</template>

<script>
import { mapActions } from 'vuex'
export default {
  data() {
    return {
      pricing: {},
    }
  },
  methods: {
    ...mapActions('User', ['getUserPrices']),
    initData() {
      this.getUserPrices()
        .then(data => {
          this.pricing = data
          console.log(this.pricing)
        })
        .catch(e => console.log(e))
    },
  },
  mounted() {
    this.initData()
  },
}
</script>

<style lang="scss" scoped>
.title-table {
  border-bottom: 2px solid #19385a;
}
.box {
  border-radius: 20px !important;
}
.button {
  position: relative;
  button {
    width: max-content;
    position: absolute;
    padding: 5px 15px;
    top: 50%;
    transform: translateY(-50%);
    right: 0;
    background-color: transparent;
    color: #fff;
    border: 1px solid #f1f601;
    transition: 0.3s ease-out;
    &:hover {
      background: #f1f601;
      color: black;
    }
  }
}
</style>
