<template>
  <div class="cryptocurrencies-container">
    <div class="cryptocurrencies__balance">
      <div class="balance__details">
        <!-- <img src="~@assets/img/Bitcoin.svg" alt="" /> -->

        <div class="balance-text">
          <span>{{ $t('account_page.cryptocurrencies.balance') }}</span>
        </div>
        <div class="balance-buttons">
          <button class="deposit">
            {{ $t('account_page.cryptocurrencies.deposit') }}
          </button>
          <button class="withdraw" @click="$emit('openWithdraw')">
            {{ $t('account_page.cryptocurrencies.withdraw') }}
          </button>
        </div>
      </div>
      <div class="balance__tabs">
        <div class="hide-button">
          <button class="hide-balance" @click="handleClickHideBalance">
            <img src="~@assets/img/hide.svg" alt="hide" />
            {{ hideBalances ? 'Show' : 'Hide' }} balance
          </button>
        </div>
        <div class="balance">
          <div class="account-balance-box">
            <div class="account-balance title">
              <span>{{
                $t('account_page.cryptocurrencies.account_balance')
              }}</span>
            </div>
            <div class="bitcoin-value" v-if="hideBalances">
              <span>{{ emojiHide }}</span>
              <!--              <span class="btc" style="opacity: 0">USDT</span>-->
            </div>
            <div
              class="bitcoin-value"
              v-else-if="!wallets.length"
              v-for="{ name, currencySign } in currency"
            >
              <span> {{ 0 }}</span>
              <span class="btc" style="margin-left: 5px"
                >{{ currencySign }}
              </span>
            </div>
            <div class="bitcoin-value" v-else v-for="wallet in wallets">
              <span> {{ wallet.balance | balanceFormatter }}</span>
              <span class="btc" style="margin-left: 5px"
                >{{ wallet.availableCurrency.name }}
              </span>
            </div>
          </div>
          <!-- <div class="bitcoin-balance-box">
            <div class="estimate-value title">
              <span>Estimated Value:</span>
            </div>
            <div class="dollar-value">
              <span>$0.00</span>
            </div>
          </div> -->
        </div>
      </div>

      <!-- <apexchart
        type="area"
        height="250px"
        ref="chart"
        :options="chartData.options"
        :series="series"
      /> -->
      <!-- :series="series" -->

      <!-- <div class="list-of-earns">
        <div class="item-header item">
          <div class="date">{{ $t('account_page.cryptocurrencies.date') }}</div>
          <div class="cash">
            {{ $t('account_page.cryptocurrencies.change') }}
          </div>
          <div class="balance">
            {{ $t('account_page.cryptocurrencies.balance') }}
          </div>
        </div>
        <div
          class="item"
          v-for="(item, i) in getterMeEarnData.slice().reverse()"
          :key="i"
        >
          <div class="date">
            {{ item.createdAt | luxon('MM-dd-yyyy hh:mm') }}
          </div>
          <div class="cash" :class="{ negative: item.cash < 0 }">
            <span v-if="!hideBalances">
              {{ !!item.cash ? '+' : '-' }}
              {{ item.cash | currency }}
            </span>
            <span v-else>
              {{ 1 | hideCurrency(3) }}
            </span>
          </div>
          <div class="balance">
            {{ item.balance | currency | hideCurrency(3, hideBalances) }}
          </div>
        </div>
      </div>
      <div class="balance_add">
        <button class="add_balace" @click="$emit('click')">Add balance</button>
      </div> -->
    </div>
  </div>
</template>

<script>
import VueApexCharts from 'vue-apexcharts'

import { mapGetters, mapMutations, mapState } from 'vuex'
import { DateTime } from 'luxon'

function getRandomInt(max) {
  return Math.floor(Math.random() * max)
}

export default {
  components: {
    apexchart: VueApexCharts,
  },

  data: () => ({
    topCrypto: [
      {
        name: 'BTC',
        image: require('@/assets/img/Bitcoin.svg'),
        displayName: 'Bitcoin',
      },
      {
        name: 'ETH',
        image: require('@/assets/img/Component.svg'),
        displayName: 'Ethereum',
      },
      {
        name: 'SOL',
        image: require('@/assets/img/PancakeSwap.svg'),
        displayName: 'Solana',
      },
      {
        name: 'ADA',
        image: require('@/assets/img/Filecoin.svg'),
        displayName: 'Cardano',
      },
    ],
  }),

  watch: {
    series() {
      this.$refs.chart.updateSeries()
      this.$refs.chart.updateSeries(
        [
          {
            data: this.series,
          },
        ],
        true,
        true
      )
    },
  },

  computed: {
    ...mapState('App', ['hideBalances']),
    ...mapGetters('User', ['getterMeBalance', 'getterMeEarnData']),
    ...mapGetters('Finance', ['getterFuturesPrices']),
    ...mapState('User', ['currency', 'wallets']),

    emojiHide() {
      return this.$options.filters.hideCurrency()
    },

    categoryList() {
      return this.getterMeEarnData.map(e =>
        DateTime.fromISO(e.createdAt).toFormat('dd.MM HH:mm')
      )
    },

    series() {
      return [
        {
          name: 'USDT',
          data: this.getterMeEarnData.map(e => {
            return e.balance.toFixed(2)
          }),
        },
      ]
    },

    chartData() {
      return {
        options: {
          series: this.series,
          colors: ['#F1F602'],
          grid: {
            show: false,
          },
          dataLabels: {
            enabled: false,
          },
          chart: {
            id: 'apexchart-wallet',
            foreColor: '#607B9D',

            animations: {
              enabled: true,
              easing: 'linear',
              speed: 1000,
            },

            toolbar: {
              show: false,
            },
            zoom: {
              enabled: false,
            },
          },
          stroke: {
            curve: 'smooth',
            show: true,
            lineCap: 'butt',
            colors: undefined,
            width: 3,
            dashArray: 0,
          },
          legend: {
            show: false,
          },
          yaxis: {
            show: false,
          },
          xaxis: {
            categories: this.categoryList,
            axisBorder: {
              show: false,
            },
            axisTicks: {
              show: true,
              borderType: 'solid',
              color: '#3A5777',
              height: 6,
              offsetX: 0,
              offsetY: 0,
            },
          },
        },
      }
    },
  },

  methods: {
    ...mapMutations('App', ['TOGGLE_HIDE_BALANCES']),

    handleClickHideBalance() {
      this.TOGGLE_HIDE_BALANCES(!this.hideBalances)
    },
  },

  filters: {
    balanceFormatter(balance) {
      let formatter = new Intl.NumberFormat('en', {
        maximumFractionDigits: 2
      })
      return formatter.format(balance)
    }
  }
}
</script>

<style></style>
