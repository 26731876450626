<template>
  <div class="wraper">
    <button
      @click="$emit('hideModal')"
      class="close-btn btn btn-close"
    ></button>
    <div class="container">
      <div class="row">
        <div class="col d-flex align-items-center justify-content-center">
          <div class="modal_body">
            <div class="modal_header row text-center">
              <h4>Add balance</h4>
            </div>
            <div class="madal_content row">
              <input
                v-model="value"
                type="text"
                placeholder="Enter the amount"
              />
            </div>
            <button>Continue</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'modal',
  data: () => ({
    value: '',
  }),
  watch: {
    value(e) {
      this.value = e.replace(/\D/g, '')
    },
  },
}
</script>

<style src="./modal.scss" lang="scss"></style>
