<template>
  <div class="wraper">
    <div class="container">
      <div class="row">
        <div class="col">
          <button
            @click="$emit('hideWithdraw')"
            class="close-btn btn btn-close"
          ></button>
          <div class="container">
            <div class="row">
              <div class="col d-flex align-items-center justify-content-center">
                <div class="modal_body form_content">
                  <div class="modal_header row text-center">
                    <h4>Withdraw</h4>
                  </div>
                  <form class="madal_content row" @submit.prevent="onSubmit">
                    <div class="form_group">
                      <label>Chose methods</label>
                      <b-dropdown
                        :text="
                          form.method.length > 1
                            ? form.method
                            : 'Chose payment method'
                        "
                      >
                        <b-dropdown-item
                          v-for="{ method, id } in paymentMethod"
                          :key="id"
                          @click="chosePaymentMethods(method)"
                          >{{ method }}</b-dropdown-item
                        >
                      </b-dropdown>
                    </div>
                    <div class="form_group">
                      <label>Wallet</label>
                      <b-dropdown
                        :text="
                          form.wallet.length > 1
                            ? form.wallet
                            : 'Chose payment method'
                        "
                      >
                        <b-dropdown-item
                          v-for="{ name, currencySign, _id } in currency"
                          :key="_id"
                          @click="choseWallet(name)"
                          >{{ name }} {{ currencySign }}</b-dropdown-item
                        >
                      </b-dropdown>
                    </div>
                    <div class="form_group">
                      <label for="amount">Amount</label>
                      <input
                        id="amount"
                        v-model="form.amount"
                        type="text"
                        placeholder="Enter the amount"
                      />
                    </div>
                    <button type="submit">Continue</button>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'

export default {
  name: 'withdraw',
  data: () => ({
    form: {
      method: '',
      wallet: '',
      amount: '',
    },
    paymentMethod: [
      {
        id: 1,
        method: 'Master Card',
      },
      {
        id: 2,
        method: 'Visa',
      },
      {
        id: 3,
        method: 'PayPal',
      },
      {
        id: 4,
        method: 'BTC',
      },
    ],
  }),
  watch: {
    'form.amount'(e) {
      this.form.amount = e.replace(/\D/g, '')
    },
  },
  computed: {
    ...mapState('User', ['currency']),
  },
  methods: {
    chosePaymentMethods(methods) {
      this.form.method = methods
    },
    choseWallet(wallet) {
      this.form.wallet = wallet
    },
    onSubmit() {
      console.log(this.form)
    },
  },
}
</script>

<style lang="scss" src="./modal.scss" />
