<template>
  <div class="container-fluid p-0 m-0">
    <Modal v-if="isModalActive" @hideModal="setModal" />
    <Withdraw v-if="isWithdraw" @hideWithdraw="setWithdarw" />
    <FeedOffModal v-if="feedOffModal" @hideModal="setFeedOff" />
    <div class="account-page container-fluid">
      <div class="container container__user-layout">
        <div class="row">
          <div class="col-md-6 col-sm-7 cryptocurrencies">
            <CryptoCurrency @click="setModal" @openWithdraw="setWithdarw" />
            <trading />
            <Pricing @openFeedOff="setFeedOff" />
            <AdditionalBalance />
          </div>
          <div class="col-md-6 col-sm-5 activity">
            <Activity />
            <Announcements />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {
  Pricing,
  CryptoCurrency,
  Activity,
  AdditionalBalance,
  Announcements,
  Orders,
} from './components'
import orders from './components/Orders.vue'
import trading from './Trading.vue'
import Modal from './modal/addBalance.vue'
import Withdraw from './modal/withdraw.vue'
import FeedOffModal from './modal/modalFeedOff.vue'
import { mapActions, mapState } from 'vuex'
export default {
  data() {
    return {
      max: 100,
      value: 33.3333,
      isModal: false,
      isWithdrawActive: false,
      feedOffModal: false,
    }
  },

  components: {
    CryptoCurrency,
    Activity,
    orders,
    trading,
    Announcements,
    AdditionalBalance,
    Orders,
    Modal,
    Withdraw,
    Pricing,
    FeedOffModal,
  },
  computed: {
    ...mapState('User', ['pricing']),
    isModalActive() {
      return this.isModal
    },
    isWithdraw() {
      return this.isWithdrawActive
    },
  },
  methods: {
    ...mapActions('User', ['getUserPrices', 'getAdditionalBalance']),
    setModal() {
      this.isModal = !this.isModal
    },
    setWithdarw() {
      this.isWithdrawActive = !this.isWithdrawActive
    },
    setFeedOff() {
      this.feedOffModal = !this.feedOffModal
    },
  },
  mounted() {
    this.getUserPrices()
    this.getAdditionalBalance()
  },
}
</script>

<style lang="scss" src="./styles.scss"></style>
