<template>
  <div class="trading-panel">
    <template v-if="info">
      <div class="box">
        <div class="trading__titles">
          <div class="text">
            <span>{{ $t('account_page.traiding.title') }}</span>
          </div>
          <div class="fee" @click="$router.push({ name: 'referral' })">
            <span>{{ $t('account_page.traiding.free') }}</span>
          </div>
        </div>
        <div class="maker_taker">
          <div class="maker">
            <div class="text">
              <span>{{ $t('account_page.traiding.referral') }}</span>
            </div>
            <div class="percent">
              <span>{{ info.count }}</span>
            </div>
          </div>
          <div class="taker">
            <div class="text">
              <span>{{ $t('account_page.traiding.balance') }}</span>
            </div>
            <div class="percent">
              <span>{{ info.balance | currency }}</span>
            </div>
          </div>
        </div>
      </div>
      <div class="ugrade">
        <div class="evaluated">
          <div class="ugrade-text">
            <span>{{ $t('account_page.traiding.referal_link') }}</span>
          </div>
          <div class="evaluated-text">
            <!-- <span>Evaluated at 00:00 AM (UNC) every day</span> -->
          </div>
        </div>
        <div class="trade-volume">
          <div class="link-box">
            <div class="copy" @click="copyLink">
              {{ $t('account_page.traiding.copy_link') }} <span>⎘</span>
            </div>
            <input type="text" readonly :value="refLink" />
          </div>
          <div class="qr-box" ref="qrBox"></div>
        </div>
      </div>
    </template>
    <template v-else>
      <div class="box">
        <UiPreloader />
      </div>
      <div class="ugrade"></div>
    </template>
  </div>
</template>

<script>
import QRCode from 'qrcode'
import copy from 'copy-to-clipboard'

import { mapActions } from 'vuex'

export default {
  data: () => ({
    value: 10,

    info: undefined,
  }),

  computed: {
    refLink() {
      if (!this.info) return ''

      const resolved = this.$router.resolve({
        name: 'sign-up',
        query: { referral: this.info._id },
      })

      return window.location.origin + resolved.href
    },
  },

  mounted() {
    this.getReferralInfo().then(info => {
      this.info = info

      this.$nextTick(() => {
        QRCode.toCanvas(
          this.refLink,
          {
            width: 170,

            color: {
              dark: '#0F2137',
              light: '#A9C7EF',
            },
          },
          (error, canvas) => {
            if (error) console.error(error)
            this.$refs.qrBox.appendChild(canvas)
          }
        )
      })
    })
  },

  methods: {
    ...mapActions('Referral', ['getReferralInfo']),

    copyLink() {
      copy(this.refLink)
    },
  },
}
</script>

<style></style>
