<template>
  <div class="box">
    <div class="orders__titles">
      <div class="order">
        <span>{{ $t('account_page.orders.open') }}</span>
      </div>
      <div class="watch-more">
        <span>{{ $t('account_page.orders.watch') }}</span>
      </div>
    </div>
    <div class="orders__custom-tabs">
      <b-tabs content-class="mt-3">
        <b-tab title="Pair date" active>
          <div class="not-found">
            <img src="~@assets/img/cards.png" alt="not-found" />
          </div>
        </b-tab>
        <b-tab title="Tepe/Side Trigger Conditions" class="trigger">
          <div class="not-found">
            <img src="~@assets/img/cards.png" alt="not-found" />
          </div>
        </b-tab>
        <b-tab title="Price Amount">
          <div class="not-found">
            <img src="~@assets/img/cards.png" alt="not-found" />
          </div>
        </b-tab>
        <b-tab title="Total Filled">
          <div class="not-found">
            <img src="~@assets/img/cards.png" alt="not-found" />
          </div>
        </b-tab>
        <b-tab title="Action">
          <div class="not-found">
            <img src="~@assets/img/cards.png" alt="not-found" />
          </div>
        </b-tab>
      </b-tabs>
    </div>
  </div>
</template>

<script>
export default {}
</script>

<style></style>
